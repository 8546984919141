<template>
  <!-- Content Add Modal -->
  <b-modal
    id="participant-info-modal"
    centered
    title="Info Peserta"
    size="lg"
  >
    <div>
      <b-form-group>
        <b-row v-if="(participant.user && participant.user.profile)">
          <b-col lg="6">
            <b-form-group
              label="Nama"
            >
            <strong>{{ participant.user.name || '-'   }}</strong>
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group
              label="Email"
            >
            <strong>{{ participant.user.email || '-'  }}</strong>
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group
              label="Gelar Depan"
            >
            <strong>{{ participant.user.profile.gelar_depan || '-'  }}</strong>
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group
              label="Gelar Belakang"
            >
            <strong>{{ participant.user.profile.gelar_belakang || '-'  }}</strong>
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group
              label="Golongan"
            >
            <strong>{{ participant.user.profile.golongan || '-'  }}</strong>
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group
              label="Jabatan"
            >
            <strong>{{ participant.user.profile.jabatan || '-'  }}</strong>
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group
              label="NIP"
            >
            <strong>{{ participant.user.profile.nip || '-'  }}</strong>
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group
              label="Alamat"
            >
            <strong>{{ participant.user.profile.address || '-'  }}</strong>
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group
              label="No HP"
            >
            <strong>{{ participant.user.profile.phone || '-' }}</strong>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="participant.requirement_file">
          <b-col lg="6">
            <b-form-group
              label="Berkas Persyaratan"
            >
            <b-button
              variant="outline-primary"
              :href="participant.requirement_file"
              size="sm"
              target="_blank"><i class="ti ti-download"></i> Buka</b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>
    </div>
    <template #modal-footer>
        <div class="w-100">
          <b-button
            v-if="participant.status !== 'Diterima'"
            variant="outline-success"
            class="align-items-center px-1 ml-1 float-right"
            size="sm"
            title="Terima"
            @click="handleAccept()"
          >
            <i class="ti ti-check" /> Terima
          </b-button>
          <b-button
            v-if="participant.status == 'Menunggu Persetujuan'"
            variant="outline-warning"
            class="align-items-center px-1 ml-1 float-right"
            size="sm"
            title="Tolak"
            @click="handleReject()"
          >
            <feather-icon icon="XIcon" /> Tolak
          </b-button>
          <b-button
            variant="outline-danger"
            class="align-items-center px-1 ml-1 float-right"
            size="sm"
            title="Tolak"
            @click="handleDelete()"
          >
            <i class="ti ti-trash" /> Hapus
          </b-button>
        </div>
      </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  BButton,
  BFormInput,
  BFormGroup,
  BFormRadioGroup,
  BFormTextarea,
  BSpinner,
  BFormFile,
  BRow,
  BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  reqDeleteTrainingParticipant,
  reqGetTrainingParticipant,
  reqUpdateTrainingParticipant,
} from '@/api/admin/trainingParticipant'

export default {
  components: {
    BModal,
    BButton,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BFormFile,
    BFormTextarea,
    BSpinner,
    BRow,
    BCol,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    propsParticipant: {
      required: false,
      type: Object
    }
  },
  data() {
    return {
      isLoading: false,
      participant: {}
    }
  },
  watch: {
    'propsParticipant.row.id': {
      handler(newValue, oldValue) {
        this.loadParticipant()
      },
      deep: true
    }
  },
  methods: {
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    loadParticipant() {
      if (this.propsParticipant.row) {
        this.isLoading = true
        reqGetTrainingParticipant(this.propsParticipant.row.id).then(response=>{
          this.participant = response.data.data
          this.isLoading = false
        }).catch(error=>{
          this.showToast(
            'danger',
            'XIcon',
            'Gagal',
            'Gagal memuat data',
          )
          this.isLoading = false
          this.participant = {}
        })
      }
    },

    async handleAccept() {
      await reqUpdateTrainingParticipant(this.propsParticipant.row.id, { status: 'Diterima' })
        .then(response => {
          this.showToast(
            'success',
            'Check',
            'Berhasil',
            'Berhasil menerima user',
          )
          this.$bvModal.hide('participant-info-modal')
          this.$emit('accept', this.propsParticipant)
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Gagal',
              icon: 'Check',
              message: error.response.data.message || 'Gagal menerima user',
              variant: 'danger',
            },
          })
        })
    },
    async handleReject() {
      await reqUpdateTrainingParticipant(this.propsParticipant.row.id, { status: 'Ditolak' })
        .then(response => {
          this.showToast(
            'success',
            'Check',
            'Berhasil',
            'Berhasil menolak user',
          )
          this.$bvModal.hide('participant-info-modal')
          this.$emit('reject', this.propsParticipant)
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Gagal',
              icon: 'Check',
              message: error.response.data.message || 'Gagal menerima user',
              variant: 'danger',
            },
          })
        })
    },
    handleDelete() {
      this.$swal({
        title: 'Apakah Anda Yakin?',
        text: 'Anda tidak akan bisa mengembalikan data ini lagi!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
      }).then(result => {
        if (result.isConfirmed) {
          reqDeleteTrainingParticipant(this.propsParticipant.row.id)
            .then(response => {
              this.showToast(
                'success',
                'Check',
                'Berhasil',
                response.data.message || 'Berhasil menghapus data',
              )
              this.$bvModal.hide('participant-info-modal')
              this.$emit('delete', this.propsParticipant)
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Gagal',
                  icon: 'Check',
                  message:
                  error.response.data.message || 'Gagal menghapus data',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
#content-add-modal {
  .modal-body {
    padding: 1.5rem 2rem;
  }

  .modal-footer {
    display: none !important;
  }
}
</style>
