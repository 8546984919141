<template>
  <b-card class="classroom-header mb-1">
    <b-carousel
      id="carousel-1"
      :interval="4000"
      controls
      indicators
      background="#ababab"
      img-height="200px"
      style="text-shadow: 1px 1px 2px #333;"
      class="training-banner"
    >
      <!-- Text slides with image -->
      <b-carousel-slide
        v-for="(item, index) in headerData.classroomCovers"
        :key="index"
      >
        <template #img>
          <img
            class="d-block w-100 training-banner"
            :src="item.image"
            alt="image slot"
          >
        </template>
      </b-carousel-slide>
    </b-carousel>
    <!-- profile navbar -->
    <div class="classroom-header-nav">
      <div class="p-2 d-md-flex align-items-center">
        <b-img
          class="classroom-img"
          :text="avatarText(headerData.classroomTitle)"
          :src="headerData.classroomLogo"
          rounded
          fluid
          alt="classroom photo"
        />
        <div class="w-100 ml-md-2 mt-1 mt-md-0 d-flex justify-content-between">
          <div>
            <h2>
              {{ headerData.classroomTitle }}
            </h2>
            <div>
              <span
                :class="[
                  headerData.status == 'Belum Mulai'
                    ? 'badge-secondary'
                    : headerData.status == 'Pendaftaran'
                    ? 'badge-warning'
                    : headerData.status == 'Sedang Berlangsung'
                    ? 'badge-danger'
                    : 'badge-success',
                ]"
                class="badge d-block d-md-inline"
                >{{ headerData.status }}</span
              >
              <span class="text-danger ml-md-1">
              {{ headerData.start_at | moment("Do MMM YYYY") }} -
              {{ headerData.end_at | moment("Do MMM YYYY") }}
              </span>
            </div>
            <div class="d-flex mt-1">
              <b-badge
                class="mr-1"
                variant="light-info"
              >
                <i class="ti ti-user" />
                {{ headerData.teacherName }}
              </b-badge>
            </div>
          </div>
          <!-- <div>
              <b-form-checkbox
                class="mt-1"
                @change="toggleEditClassroomMode"
                name="check-button"
                switch
                inline
              >
                Mode Edit Kelas
              </b-form-checkbox>
            </div> -->
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BBadge, BCard, BImg, BFormCheckbox, BCarousel, BCarouselSlide,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BBadge,
    BImg,
    BFormCheckbox,
    BCarousel,
    BCarouselSlide,
  },
  directives: {
    Ripple,
  },
  props: {
    headerData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isEditClassroomMode: false,
    }
  },
  methods: {
    toggleEditClassroomMode(event) {
      this.isEditClassroomMode = event
      this.$emit('isEditModeOn', this.isEditClassroomMode)
    },
    toggleNavtab(tab) {
      this.activeNavtab = tab
      this.$emit('activeNavtab', this.activeNavtab)
    },
    avatarText,
  },
}
</script>

<style lang="scss" scoped>
.card-img-top {
  max-height: 200px;
  object-fit: cover;
  // object-position: top;
}

.classroom-header {
  .classroom-img {
    border: 0.357rem solid #fff;
    background-color: #fff;
    border-radius: 0.428rem;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin-top: -75px;
    z-index: 1;
  }
}

@media (max-width: 768px) {
  .classroom-header .classroom-img {
    width: 80px; /* Adjust the desired width for mobile */
    height: 80px; /* Adjust the desired height for mobile */
    margin-left: auto;
    position: relative;
  }
}

.button {
  i {
    margin-right: 0.5rem;
  }
}
.training-banner {
  width: 100%;
  height: calc(100vw/5);
}
</style>
