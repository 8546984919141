<template>
  <!-- Content Add Modal -->
  <b-modal
    id="content-add-modal"
    centered
    title="Tambah Pertemuan"
    size="lg"
  >
    <div>
      <b-form-group
        label="Judul Pertemuan"
        :invalid-feedback="classroomContent.errors.title.message"
      >
        <b-form-input
          v-model="classroomContent.data.title"
          required
          :state="classroomContent.errors.title.state"
        />
      </b-form-group>
      <b-form-group
        label="Sampul"
        :invalid-feedback="classroomContent.errors.cover.message"
      >
        <b-form-file
          v-model="classroomContent.data.cover"
          required
          :state="classroomContent.errors.cover.state"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          accept="image/*"
        />
      </b-form-group>
      <b-form-group>
        <b-row>
          <b-col lg="6">
            <b-form-group
              v-slot="{ ariaDescribedby }"
              label="Tipe Konten"
              :invalid-feedback="classroomContent.errors.content_type.message"
            >
              <b-form-radio-group
                id="radio-content-type"
                v-model="classroomContent.data.content_type"
                name="content_type"
                :state="classroomContent.errors.content_type.state"
                :options="[
                  { text: 'Video', value: 'Video' },
                  { text: 'E-Book', value: 'E-Book' },
                ]"
                :aria-describedby="ariaDescribedby"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group
              label="Konten"
              :invalid-feedback="classroomContent.errors.content.message"
            >
              <b-form-file
                v-model="classroomContent.data.content"
                required
                :state="classroomContent.errors.content.state"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                accept="video/*, .pdf"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>
      <b-form-group
        label="Online Meet Url"
        :invalid-feedback="classroomContent.errors.online_meet_url.message"
      >
        <b-form-input
          v-model="classroomContent.data.online_meet_url"
          type="url"
          required
          :state="classroomContent.errors.online_meet_url.state"
        />
      </b-form-group>

      <b-form-group>
        <b-row>
          <!-- Start Date -->
          <b-col lg="6">
            <b-form-group
              label="Tanggal Mulai"
              :invalid-feedback="classroomContent.errors.start_at.message"
            >
              <flat-pickr
                v-model="classroomContent.data.start_at"
                class="form-control"
                :class="{
                  'is-invalid': classroomContent.errors.start_at.state != null,
                }"
                :config="{
                  altInput: true,
                  altFormat: 'j F Y (H:i)',
                  dateFormat: 'Y-m-d H:i',
                  minDate: 'today',
                  enableTime: true,
                  time_24hr: true,
                }"
              />
            </b-form-group>
          </b-col>

          <!-- Start Date -->
          <b-col lg="6">
            <b-form-group
              label="Tanggal Selesai"
              :invalid-feedback="classroomContent.errors.end_at.message"
            >
              <flat-pickr
                v-model="classroomContent.data.end_at"
                class="form-control"
                :class="{
                  'is-invalid': classroomContent.errors.end_at.state != null,
                }"
                :config="{
                  altInput: true,
                  altFormat: 'j F Y (H:i)',
                  dateFormat: 'Y-m-d H:i',
                  minDate: 'today',
                  enableTime: true,
                  time_24hr: true,
                }"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>
      <b-form-group>
        <b-row>
          <b-col lg="6">
            <b-form-group
              label="Durasi Pelatihan"
              :invalid-feedback="
                classroomContent.errors.training_duration.message
              "
            >
              <b-form-input
                v-model="classroomContent.data.training_duration"
                type="number"
                required
                :state="classroomContent.errors.training_duration.state"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group
              v-slot="{ ariaDescribedby }"
              label="Tipe Durasi"
              :invalid-feedback="classroomContent.errors.duration_type.message"
            >
              <b-form-radio-group
                id="radio-duration_type"
                v-model="classroomContent.data.duration_type"
                :state="classroomContent.errors.duration_type.state"
                :options="[
                  { text: 'Menit', value: 'Menit' },
                  { text: 'Jam', value: 'Jam' },
                  { text: 'Hari', value: 'Hari' },
                ]"
                :aria-describedby="ariaDescribedby"
                name="duration_type"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>
      <b-form-group
        label="Deskripsi Pertemuan"
        :invalid-feedback="classroomContent.errors.description.message"
      >
        <b-form-textarea
          v-model="classroomContent.data.description"
          required
          rows="3"
          no-resize
          :state="classroomContent.errors.description.state"
        />
      </b-form-group>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="w-100"
        variant="primary"
        @click="handleSubmit"
      >
        <i
          v-show="!isLoading"
          class="font-medium-2 ti ti-circle-plus"
        />
        <b-spinner
          v-show="isLoading"
          small
          variant="light"
        />
        Buat Pertemuan
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import axios from 'axios'
import {
  BModal,
  BButton,
  BFormInput,
  BFormGroup,
  BFormRadioGroup,
  BFormTextarea,
  BSpinner,
  BFormFile,
  BRow,
  BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { checkAuthorizeRole } from '@/auth/utils'
import { reqCreateTrainingChapter } from '@/api/admin/trainingChapter'
import { forEach } from 'postcss-rtl/lib/affected-props'

export default {
  components: {
    BModal,
    BButton,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BFormFile,
    BFormTextarea,
    BSpinner,
    BRow,
    BCol,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoading: false,
      classroomContent: {
        data: {
          title: '',
          description: '',
          cover: null,
          content_type: 'Video',
          content: null,
          online_meet_url: '',
          training_duration: null,
          duration_type: 'Menit',
          start_at: '',
          end_at: '',
        },
        errors: {
          title: { state: null, message: '' },
          description: { state: null, message: '' },
          cover: { state: null, message: '' },
          content_type: { state: null, message: '' },
          content: { state: null, message: '' },
          online_meet_url: { state: null, message: '' },
          training_duration: { state: null, message: '' },
          duration_type: { state: null, message: '' },
          start_at: { state: null, message: '' },
          end_at: { state: null, message: '' },
        },
      },
    }
  },
  mounted() {
  },
  methods: {
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    resetForm() {
      this.classroomContent.data.title = ''
      this.classroomContent.data.description = ''
      this.classroomContent.data.cover = null
      this.classroomContent.data.content_type = null
      this.classroomContent.data.content = null
      this.classroomContent.data.online_meet_url = ''
      this.classroomContent.data.training_duration = null
      this.classroomContent.data.duration_type = null
      this.classroomContent.data.start_at = null
      this.classroomContent.data.end_at = null
    },
    resetError() {
      this.classroomContent.errors.title.state = null
      this.classroomContent.errors.description.state = null
      this.classroomContent.errors.cover.state = null
      this.classroomContent.errors.content_type.state = null
      this.classroomContent.errors.content.state = null
      this.classroomContent.errors.online_meet_url.state = null
      this.classroomContent.errors.training_duration.state = null
      this.classroomContent.errors.duration_type.state = null
      this.classroomContent.errors.start_at.state = null
      this.classroomContent.errors.end_at.state = null
    },
    formValidation() {
      this.isLoading = true
      const isValid = true
      return isValid
    },
    async handleSubmit(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.formValidation()) {
        // wrap into form data
        const data = new FormData()

        for (const index in this.classroomContent.data) {
          data.append(index, this.classroomContent.data[index])
        }
        this.resetError()

        // create classroom content
        await reqCreateTrainingChapter(this.$route.params.trainingId, data)
          .then(response => {
            // set loading
            this.isLoading = false

            // reset modal content value
            this.resetForm()

            this.showToast(
              'success',
              'Check',
              'Berhasil',
              'Pertemuan berhasil dibuat',
            )
            this.$bvModal.hide('content-add-modal')
            this.$emit('pushNewData', response.data.data)
          })
          .catch(error => {
            this.isLoading = false
            const { errors } = error.response.data
            for (const index in errors) {
              const element = errors[index]
              this.classroomContent.errors[index].state = false
              this.classroomContent.errors[index].message = element[0]
            }
          })
      }
    },
  },
}
</script>

<style lang="scss">
#content-add-modal {
  .modal-body {
    padding: 1.5rem 2rem;
  }

  .modal-footer {
    display: none !important;
  }
}
</style>
