import axios from '@/libs/axios'

export function reqGetTrainingChapters(trainingId, payload) {
  return axios.get(`api/admin/trainings/${trainingId}/chapters`, { params: payload })
}
export function reqGetTrainingChapter(id) {
  return axios.get(`api/admin/chapters/${id}`)
}
export function reqCreateTrainingChapter(trainingId, payload) {
  return axios.post(`api/admin/trainings/${trainingId}/chapters`, payload)
}
export function reqUpdateTrainingChapter(id, payload) {
  return axios.post(`api/admin/chapters/${id}?_method=PATCH`, payload)
}
export function reqDeleteTrainingChapter(id) {
  return axios.delete(`api/admin/chapters/${id}`)
}
