import axios from '@/libs/axios'

export function reqGetTrainingCertificates(trainingId, payload) {
  return axios.get(`api/admin/trainings/${trainingId}/certificates`, { params: payload })
}
export function reqGetTrainingCertificate(id) {
  return axios.get(`api/admin/certificates/${id}`)
}
export function reqCreateTrainingCertificate(trainingId, payload) {
  return axios.post(`api/admin/trainings/${trainingId}/certificates`, payload)
}
export function reqUpdateTrainingCertificate(id, payload) {
  return axios.post(`api/admin/certificates/${id}?_method=PATCH`, payload)
}
export function reqDeleteTrainingCertificate(id) {
  return axios.delete(`api/admin/certificates/${id}`)
}
