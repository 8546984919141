var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-card',[_c('div',{staticClass:"d-flex align-items-center line-height-1 pb-2"},[_c('i',{staticClass:"text-primary ti ti-users small-gap-right"}),_c('h4',{staticClass:"text-primary mb-0"},[_vm._v(" Daftar Peserta ")])]),_c('div',[_c('vue-good-table',{ref:"table",attrs:{"line-numbers":true,"columns":_vm.columns,"rows":_vm.rows,"total-rows":_vm.rows.length,"is-loading":_vm.isLoading,"sort-options":{
              enabled: true,
              initialSortBy: { field: 'fullName', type: 'asc' },
            },"select-options":{
              // enabled: true,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            },"pagination-options":{
              enabled: true,
              nextLabel: 'selanjutnya',
              prevLabel: 'sebelumnya',
              rowsPerPageLabel: 'Data per halaman',
              ofLabel: 'dari',
              pageLabel: 'halaman', // for 'pages' mode
              allLabel: 'Semua',
            }},on:{"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'fullName')?_c('span',{staticClass:"text-nowrap"},[_c('b-avatar',{staticClass:"mx-1",attrs:{"text":_vm.avatarText(props.row.fullName),"src":props.row.avatar,"variant":"light-primary"}}),_vm._v(" "+_vm._s(props.row.fullName)+" ")],1):(props.column.field === 'status')?_c('span',{staticClass:"text-nowrap"},[_c('b-badge',{attrs:{"variant":props.row.status == 'Diterima'
                      ? 'success'
                      : props.row.status == 'Ditolak'
                        ? 'danger'
                        : 'secondary'}},[_vm._v(_vm._s(props.row.status))])],1):(props.column.field === 'is_done')?_c('span',{staticClass:"text-nowrap"},[_c('b-badge',{attrs:{"variant":props.row.is_done ? 'success' : 'secondary'}},[_vm._v(_vm._s(props.row.statusTraining))])],1):(props.column.field === 'action')?_c('span',[_c('b-button',{staticClass:"align-items-center px-1 ml-1",attrs:{"variant":"outline-danger","size":"sm","title":"Tolak"},on:{"click":function($event){return _vm.showParticipantInfoModal(props)}}},[_c('feather-icon',{attrs:{"icon":"SettingsIcon"}})],1)],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('vue-excel-xlsx',{staticClass:"mr-1 btn btn-warning btn-sm",attrs:{"data":_vm.dataExcel,"columns":_vm.columnsExcel,"file-name":'Daftar peserta pelatihan ' + _vm.training.title,"file-type":'xlsx',"sheet-name":'sheetname'}},[_c('i',{staticClass:"bi bi-download"}),_vm._v(" Download ")]),_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.$bvModal.show('participant-add-modal')}}},[_c('i',{staticClass:"bi bi-plus"}),_vm._v(" Tambah ")])],1)])],1)])],1),_c('participant-info-modal',{attrs:{"props-participant":_vm.selectedParticipant},on:{"delete":function($event){return _vm.getParticipants()},"accept":_vm.handleAccept,"reject":_vm.handleReject}}),_c('participant-add-modal',{on:{"reload":function($event){return _vm.getParticipants()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }