import axios from '@/libs/axios'

export function reqGetTrainingParticipants(trainingId, payload) {
  return axios.get(`api/admin/trainings/${trainingId}/participants`, { params: payload })
}
export function reqGetTrainingParticipant(id) {
  return axios.get(`api/admin/participants/${id}`)
}
export function reqCreateTrainingParticipant(trainingId, payload) {
  return axios.post(`api/admin/trainings/${trainingId}/participants`, payload)
}
export function reqUpdateTrainingParticipant(id, payload) {
  return axios.post(`api/admin/participants/${id}?_method=PATCH`, payload)
}
export function reqDeleteTrainingParticipant(id) {
  return axios.delete(`api/admin/participants/${id}`)
}
