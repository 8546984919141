<template>
  <div v-if="!isLoading">
    <classroom-header
      :header-data="headerData"
      @isEditModeOn="isEditModeOn"
    />

    <!-- Navigation Tab -->
    <div>
      <b-row class="mb-2">
        <b-col lg="12">
          <b-tabs
            pills
            class="profile-tabs mt-1 mt-md-0"
            nav-class="mb-0"
          >
            <template #tabs-start>
              <b-nav-item
                v-for="(navtab, index) in navTabs"
                :key="index"
                :active="currentTab === index"
                class="font-weight-bold small-gap-right"
                @click="currentTab = index"
              >
                <i
                  class="ti"
                  :class="navtab.icon"
                />
                <span class="d-none d-md-block">{{ navtab.title }}</span>
              </b-nav-item>
            </template>
          </b-tabs>
        </b-col>
      </b-row>
    </div>

    <!-- Content Section-->
    <div
      v-show="currentTab === 0"
      class="material-section mt-2"
    >
      <classroom-content
        :classroom-content-data="classroomContent"
        :is-edit-mode="editMode"
        :training="training"
        @setChapters="setChapters"
      />
    </div>

    <div
      v-show="currentTab === 1"
      class="student-section mt-2"
    >
      <classroom-student :active="(currentTab === 1)" :training="training" />
    </div>

    <div
      v-if="currentTab === 2"
      class="grade-section mt-2"
    >
      <classroom-grade
        :chapters="chapters"
        :active="(currentTab === 2)" :training="training"
      />
    </div>

    <div
      v-if="currentTab === 3"
      class="attendance-section mt-2"
    >
      <classroom-attendance
        :chapters="chapters"
        :active="(currentTab === 3)" :training="training"
      />
    </div>

    <div
      v-show="(currentTab === 4)"
      class="certificate-section mt-2"
    >
      <certificate :active="(currentTab === 4)" :training="training" />
    </div>

    <div
      v-if="(currentTab === 5)"
      class="setting-section mt-2"
    >
      <classroom-setting
        :training="training"
        @reload="getTraining()"
      />
    </div>
  </div>
</template>

<script>
import {
  BTabs, BNavItem, BRow, BCol,
} from 'bootstrap-vue'
import { reqGetTraining } from '@/api/admin/training'
import ClassroomHeader from './component/ClassroomHeader.vue'
import ClassroomContent from './section/ClassroomContent.vue'
import ClassroomStudent from './section/ClassroomStudent.vue'
import ClassroomGrade from './section/ClassroomGrade.vue'
import ClassroomAttendance from './section/ClassroomAttendance.vue'
import ClassroomSetting from './section/ClassroomSetting.vue'
import Certificate from './section/Certificate.vue'

export default {
  components: {
    ClassroomHeader,
    ClassroomContent,
    ClassroomStudent,
    ClassroomGrade,
    ClassroomAttendance,
    ClassroomSetting,
    Certificate,
    BTabs,
    BNavItem,
    BRow,
    BCol,
  },
  data() {
    return {
      isLoading: true,
      currentTab: 0,
      editMode: false,
      classroomId: this.$router.currentRoute.params.id,
      classroomContent: {},
      training: {},
      headerData: {
        classroomTitle: 'class_title',
        classroomLogo: '/img/avatar-s-2.da5e73c7.jpg',
        teacherName: 'teacher_name',
        classroomCovers: [],
        classroomDescription: '',
        classroomCoverImg: 'https://picsum.photos/id/10/2500/1667',
        status: '',
        start_at: '',
        end_at: ''
      },
      chapters: [],
      navTabs: [
        { icon: 'ti-book', title: 'Pertemuan' },
        { icon: 'ti-users', title: 'Peserta' },
        { icon: 'ti-star', title: 'Nilai' },
        { icon: 'ti-calendar', title: 'Kehadiran' },
        { icon: 'ti-file', title: 'Sertifikat' },
        { icon: 'ti-settings', title: 'Pengaturan' },
      ],
    }
  },
  created() {
    this.getTraining()

    if (this.$route.query.tab) {
      this.currentTab = parseInt(this.$route.query.tab)
    }
  },
  methods: {
    setChapters(data) {
      this.chapters = data
    },
    async getTraining() {
      await reqGetTraining(this.$route.params.trainingId)
        .then(response => {
          this.training = response.data.data
          const classroom = response.data.data

          this.headerData.classroomTitle = classroom.title
          this.headerData.classroomLogo = classroom.image
          this.headerData.classroomCovers = classroom.training_banners
          this.headerData.teacherName = classroom.trainer.name
          this.headerData.classroomDescription = classroom.description
          this.headerData.status = classroom.status
          this.headerData.start_at = classroom.start_at
          this.headerData.end_at = classroom.end_at

          this.classroomContent = classroom.training_chapters

          this.isLoading = false
        })
        .catch(error => {
          console.log(error)
        })
    },
    isEditModeOn(value) {
      this.editMode = value
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~@core/scss/vue/libs/vue-good-table.scss";

.card-img-overlay {
  padding: 0 !important;
}

.news-slide-content {
  border-radius: 0.428rem !important;

  img {
    height: 350px;
    border-radius: 0.428rem !important;
    object-fit: cover;
  }
}
</style>
