<template>
  <!-- Content Add Modal -->
  <b-modal
    id="participant-add-modal"
    centered
    title="Tambah Peserta"
    size="md"
  >
    <div>
      <b-form-group>

      <b-col lg="12" class="mb-1">
        <label class="d-block">User</label>
        <v-select
          v-model="formData.user_ids"
          multiple
          :reduce="(user) => user.id"
          :options="users"
          label="name"
          :class="{
            'is-invalid': formErrors.users,
          }"
        />
        <b-form-text id="req-help-block">
          Pilih User untuk menambahkan peserta pelatihan
        </b-form-text>
        <div
          v-if="formErrors.users"
          class="invalid-feedback d-block"
        >
          {{ formErrors.users[0] }}
        </div>
      </b-col>
      </b-form-group>
    </div>
    <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="outline-danger"
            class="align-items-center px-1 ml-1 float-right"
            size="sm"
            title="Tolak"
            @click="handleSubmit()"
          >
            <i class="ti ti-trash" /> Tambah Peserta
          </b-button>
        </div>
      </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  BButton,
  BFormInput,
  BFormText,
  BFormGroup,
  BFormRadioGroup,
  BFormTextarea,
  BSpinner,
  BFormFile,
  BRow,
  BCol,
} from 'bootstrap-vue'
import vSelect from "vue-select";
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  reqCreateTrainingParticipant,
} from '@/api/admin/trainingParticipant'
import { reqGetUsers } from "@/api/admin/user";

export default {
  components: {
    BModal,
    BButton,
    BFormGroup,
    BFormRadioGroup,
    BFormText,
    BFormInput,
    BFormFile,
    BFormTextarea,
    BSpinner,
    BRow,
    BCol,
    flatPickr,
    vSelect
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoading: false,
      formData: {
        user_ids: []
      },
      formErrors: [],
      users: []
    }
  },
  mounted() {
    this.getUsers()
  },
  methods: {
    getUsers() {
      reqGetUsers({ per_page: 999 }).then((response) => {
        this.users = response.data.data.data;
      });
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    async handleSubmit() {
      await reqCreateTrainingParticipant(this.$route.params.trainingId, this.formData)
        .then(response => {
          this.showToast(
            'success',
            'CheckIcon',
            'Berhasil',
            response.data.message || 'Berhasil menambah peserta',
          )
          this.$bvModal.hide('participant-add-modal')
          this.$emit('reload')
          this.formData.user_ids = []
        })
        .catch(error => {
          this.showToast(
            'danger',
            'XIcon',
            'Gagal',
            error.response.data.message || 'Gagal menambah peserta',
          )
        })
    }
  },
}
</script>

<style lang="scss">
#content-add-modal {
  .modal-body {
    padding: 1.5rem 2rem;
  }

  .modal-footer {
    display: none !important;
  }
}
</style>
