var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"content-detail-modal","hide-footer":true,"centered":"","title":"Edit Pertemuan","size":"lg"}},[(_vm.isLoading)?_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-center mt-4"},[_c('div',{staticClass:"text-center mt-4"},[_c('b-spinner',{staticClass:"mr-1",attrs:{"variant":"primary"}}),_c('p',{staticClass:"mt-1"},[_vm._v(" Memuat Detail Materi ")])],1)])],1):_c('div',[_c('b-form-group',{attrs:{"label":"Judul Pertemuan","invalid-feedback":_vm.classroomContent.errors.title.message}},[_c('b-form-input',{attrs:{"required":"","state":_vm.classroomContent.errors.title.state},model:{value:(_vm.classroomContent.data.title),callback:function ($$v) {_vm.$set(_vm.classroomContent.data, "title", $$v)},expression:"classroomContent.data.title"}})],1),_c('b-form-group',{attrs:{"label":"Sampul","description":"Kosongkan jika tidak diganti","invalid-feedback":_vm.classroomContent.errors.cover.message}},[_c('b-form-file',{attrs:{"required":"","state":_vm.classroomContent.errors.cover.state,"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here...","accept":"image/*"},model:{value:(_vm.classroomContent.data.cover),callback:function ($$v) {_vm.$set(_vm.classroomContent.data, "cover", $$v)},expression:"classroomContent.data.cover"}})],1),_c('b-form-group',[_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Tipe Konten","invalid-feedback":_vm.classroomContent.errors.content_type.message},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"id":"radio-content-type","name":"content_type","state":_vm.classroomContent.errors.content_type.state,"options":[
                { text: 'Video', value: 'Video' },
                { text: 'E-Book', value: 'E-Book' } ],"aria-describedby":ariaDescribedby},model:{value:(_vm.classroomContent.data.content_type),callback:function ($$v) {_vm.$set(_vm.classroomContent.data, "content_type", $$v)},expression:"classroomContent.data.content_type"}})]}}])})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Konten","description":"Kosongkan jika tidak diganti","invalid-feedback":_vm.classroomContent.errors.content.message}},[_c('b-form-file',{attrs:{"required":"","state":_vm.classroomContent.errors.content.state,"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here...","accept":"video/*, .pdf"},model:{value:(_vm.classroomContent.data.content),callback:function ($$v) {_vm.$set(_vm.classroomContent.data, "content", $$v)},expression:"classroomContent.data.content"}})],1)],1)],1)],1),_c('b-form-group',{attrs:{"label":"Online Meet Url","invalid-feedback":_vm.classroomContent.errors.online_meet_url.message}},[_c('b-form-input',{attrs:{"type":"url","required":"","state":_vm.classroomContent.errors.online_meet_url.state},model:{value:(_vm.classroomContent.data.online_meet_url),callback:function ($$v) {_vm.$set(_vm.classroomContent.data, "online_meet_url", $$v)},expression:"classroomContent.data.online_meet_url"}})],1),_c('b-form-group',[_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Tanggal Mulai","invalid-feedback":_vm.classroomContent.errors.start_at.message}},[_c('flat-pickr',{staticClass:"form-control",class:{
                'is-invalid': _vm.classroomContent.errors.start_at.state != null,
              },attrs:{"config":{
                altInput: true,
                altFormat: 'j F Y (H:i)',
                dateFormat: 'Y-m-d H:i',
                minDate: 'today',
                enableTime: true,
                time_24hr: true,
              }},model:{value:(_vm.classroomContent.data.start_at),callback:function ($$v) {_vm.$set(_vm.classroomContent.data, "start_at", $$v)},expression:"classroomContent.data.start_at"}})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Tanggal Selesai","invalid-feedback":_vm.classroomContent.errors.end_at.message}},[_c('flat-pickr',{staticClass:"form-control",class:{
                'is-invalid': _vm.classroomContent.errors.end_at.state != null,
              },attrs:{"config":{
                altInput: true,
                altFormat: 'j F Y (H:i)',
                dateFormat: 'Y-m-d H:i',
                minDate: 'today',
                enableTime: true,
                time_24hr: true,
              }},model:{value:(_vm.classroomContent.data.end_at),callback:function ($$v) {_vm.$set(_vm.classroomContent.data, "end_at", $$v)},expression:"classroomContent.data.end_at"}})],1)],1)],1)],1),_c('b-form-group',[_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Durasi Pelatihan","invalid-feedback":_vm.classroomContent.errors.training_duration.message}},[_c('b-form-input',{attrs:{"type":"number","required":"","state":_vm.classroomContent.errors.training_duration.state},model:{value:(_vm.classroomContent.data.training_duration),callback:function ($$v) {_vm.$set(_vm.classroomContent.data, "training_duration", $$v)},expression:"classroomContent.data.training_duration"}})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Tipe Durasi","invalid-feedback":_vm.classroomContent.errors.duration_type.message},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"id":"radio-duration_type","state":_vm.classroomContent.errors.duration_type.state,"options":[
                { text: 'Menit', value: 'Menit' },
                { text: 'Jam', value: 'Jam' },
                { text: 'Hari', value: 'Hari' } ],"aria-describedby":ariaDescribedby,"name":"duration_type"},model:{value:(_vm.classroomContent.data.duration_type),callback:function ($$v) {_vm.$set(_vm.classroomContent.data, "duration_type", $$v)},expression:"classroomContent.data.duration_type"}})]}}])})],1)],1)],1),_c('b-form-group',{attrs:{"label":"Deskripsi Pertemuan","invalid-feedback":_vm.classroomContent.errors.description.message}},[_c('b-form-textarea',{attrs:{"required":"","rows":"3","no-resize":"","state":_vm.classroomContent.errors.description.state},model:{value:(_vm.classroomContent.data.description),callback:function ($$v) {_vm.$set(_vm.classroomContent.data, "description", $$v)},expression:"classroomContent.data.description"}})],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"w-100",attrs:{"variant":"primary"},on:{"click":_vm.handleSubmit}},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],staticClass:"font-medium-2 ti ti-circle-plus"}),_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],attrs:{"small":"","variant":"light"}}),_vm._v(" Simpan Pertemuan ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }