<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <!-- Header -->
          <div class="d-flex align-items-center line-height-1 pb-2">
            <i class="text-primary ti ti-users small-gap-right" />
            <h4 class="text-primary mb-0">
              Daftar Nilai
            </h4>
          </div>

          <!-- Table of Student-->
          <div>
            <!-- table -->
            <vue-good-table
              mode="remote"
              :line-numbers="true"
              :columns="columns"
              :rows="rows"
              :total-rows="totalRecords"
              :is-loading.sync="isLoading"
              :sort-options="{
                enabled: true,
                initialSortBy: { field: 'user.name', type: 'asc' },
              }"
              :select-options="{
                // enabled: true,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                nextLabel: 'selanjutnya',
                prevLabel: 'sebelumnya',
                rowsPerPageLabel: 'Data per halaman',
                ofLabel: 'dari',
                pageLabel: 'halaman', // for 'pages' mode
                allLabel: 'Semua',
              }"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <div slot="table-actions">
                <vue-excel-xlsx
                  :data="rows"
                  :columns="columnsExcel"
                  :file-name="'Daftar nilai tugas peserta pelatihan ' + training.title"
                  :file-type="'xlsx'"
                  :sheet-name="'sheetname'"
                  class="mr-1 btn btn-warning btn-sm"
                >
                  <i class="bi bi-download" /> Download
                </vue-excel-xlsx>
              </div>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Name -->
                <span
                  v-if="props.column.field === 'fullName'"
                  class="text-nowrap"
                >
                  <b-avatar
                    :text="avatarText(props.row.fullName)"
                    :src="props.row.avatar"
                    variant="light-primary"
                    class="mx-1"
                  />
                  <span class="text-nowrap">{{ props.row.fullName }}</span>
                </span>

                <span
                  v-if="props.column.field === 'status'"
                  class="text-nowrap"
                >
                  <b-badge
                    :variant="
                      props.row.status == 'Diterima'
                        ? 'success'
                        : props.row.status == 'Ditolak'
                          ? 'danger'
                          : 'secondary'
                    "
                  >{{ props.row.status }}</b-badge>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <b-button
                    variant="outline-danger"
                    class="align-items-center px-1"
                    size="sm"
                    title="Hapus"
                    @click="handleDelete(props)"
                  >
                    <i class="ti ti-trash" />
                  </b-button>
                  <b-button
                    v-if="(['Sudah Dikerjakan', 'Sedang Dikoreksi'].includes(props.row.status) && props.row.task.type == 'Essay')"
                    variant="outline-success"
                    class="align-items-center px-1 ml-1"
                    size="sm"
                    title="Koreksi"
                    :to="{
                      name: checkAuthorizeRole('task-results.correction'),
                      params: {
                        trainingId: $route.params.trainingId,
                        taskResultId: props.row.id,
                      },
                    }"
                  >
                    <i class="ti ti-check" />
                  </b-button>
                  <b-button
                    variant="outline-primary"
                    class="align-items-center px-1 ml-1"
                    size="sm"
                    title="Koreksi"
                    :to="{
                      name: checkAuthorizeRole('task-results.correction'),
                      params: {
                        trainingId: $route.params.trainingId,
                        taskResultId: props.row.id,
                      },
                    }"
                  >
                    <i class="ti ti-eye" />
                  </b-button>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { checkAuthorizeRole } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { pascalCase, avatarText } from '@core/utils/filter'
import { reqGetTaskResults, reqDeleteTaskResult } from '@/api/admin/task'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
  },
  directives: {
    Ripple,
  },
  props: {
    chapters: {
      required: true,
      type: Array,
    },
    training: {
      required: true,
      type: Object,
    },
    active: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      rows: [],
      totalRecords: 0,
      isLoading: false,
      serverParams: {
        columnFilters: {},
        sort: {
          field: '',
          type: '',
        },
        training_id: this.$route.params.trainingId,
        page: 1,
        per_page: 10,
      },
      columnsExcel: [
        {
          label: 'Nama',
          field: 'user.name',
        },
        {
          label: 'Email',
          field: 'user.email',
        },
        {
          label: 'Nama Tugas',
          field: 'task.from.title',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Nilai',
          field: 'point',
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    taskNameFilters() {
      return this.chapters.map(chapter => ({
        value: chapter.id,
        text: chapter.title,
      }))
    },
    columns() {
      return [
        {
          label: 'Nama User',
          field: 'user.name',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Cari nama user', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: 'keyup', // only trigger on enter not on keyup
          },
        },
        {
          label: 'Nama Tugas',
          field: 'task.from.title',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Semua Tugas', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: this.taskNameFilters, // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: 'keyup', // only trigger on enter not on keyup
          },
        },
        {
          label: 'Nilai',
          field: 'point',
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Semua Status', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [
              'Belum Dikerjakan',
              'Sedang Dikerjakan',
              'Sudah Dikerjakan',
              'Sedang Dikoreksi',
              'Selesai',
            ],
            filterFn: this.columnFilterFn, // custom filter function that
            trigger: 'enter', // only trigger on enter not on keyup
          },
        },
        {
          label: 'Aksi',
          field: 'action',
          width: '130px',
          sortable: false,
        },
      ]
    },
  },
  watch: {
    active(value) {
      if (value) {
        this.getTaskResults()
      }
    },
  },
  created() {
  },
  methods: {
    triggerModal() {
      this.$bvModal.show('content-add-modal')
    },
    checkAuthorizeRole,
    pascalCase,
    avatarText,
    columnFilterFn(data, search) {
      if (data.toLowerCase().includes(search.toLowerCase())) {
        return data
      }
    },
    async getTaskResults() {
      this.isLoading = true
      await reqGetTaskResults(this.serverParams)
        .then(response => {
          this.rows = response.data.data.data
          this.totalRecords = response.data.data.total
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
        })
    },
    updateParams(newProps) {
      if (newProps.columnFilters && (newProps.columnFilters['task.from.title'] || newProps.columnFilters['task.from.title'] == '')) newProps.columnFilters.chapter_id = newProps.columnFilters['task.from.title']
      if (newProps.columnFilters && (newProps.columnFilters['user.name'] || newProps.columnFilters['user.name'] == '')) newProps.columnFilters.user_name = newProps.columnFilters['user.name']
      this.serverParams = { ...this.serverParams, ...newProps }
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.getTaskResults()
    },

    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage })
      this.getTaskResults()
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      })
      this.getTaskResults()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.getTaskResults()
    },
    handleAccept(props) {
      this.$router.push({
        name: checkAuthorizeRole('task-results.correction'),
        params: {
          trainingId: this.$route.params.trainingId,
          chapterId: this.$route.params.chapterId,
          taskResultId: props.row.id,
        },
      })
    },
    handleDelete(props) {
      this.$swal({
        title: 'Apakah Anda Yakin?',
        text: 'Anda tidak akan bisa mengembalikan data ini lagi!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
      }).then(result => {
        if (result.isConfirmed) {
          reqDeleteTaskResult(props.row.id)
            .then(response => {
              this.showToast(
                'success',
                'Check',
                'Berhasil',
                response.data.message || 'Berhasil menghapus data',
              )
              this.rows.splice(props.index, 1)
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Gagal',
                  icon: 'Check',
                  message:
                    error.response.data.message || 'Gagal menghapus data',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.small-gap-right {
  margin-right: 8px;
}

.small-gap-bottom {
  margin-bottom: 8px;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.collapse-default {
  .card {
    // border-bottom: none !important;
  }
  .material-card {
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #efefef !important;
    border-radius: 0.428rem;

    .card-body {
      padding: 1.5rem;
    }
  }
}
</style>
