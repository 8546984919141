<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <!-- Header -->
          <div class="d-flex align-items-center line-height-1 pb-2">
            <i class="text-primary ti ti-users small-gap-right" />
            <h4 class="text-primary mb-0">Formulir Daftar Kehadiran</h4>
          </div>

          <div>
            <button class="btn btn-primary" @click="downloadAttendance()">
              <i class="bi bi-download"></i> Download
            </button>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>


<script>
import { BRow, BCol, BCard, BButton } from "bootstrap-vue";
import axios from "@/libs/axios";

export default {
  props: {
    active: {
      required: true,
    },
    training: {
      required: true,
    },
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
  },
  data() {
    return {
      isLoading: false
    };
  },
  mounted() {
  },
  methods: {
    downloadAttendance() {
      axios.post("api/admin/trainings/"+this.training.id+"/attendances", [], {
        responseType: 'blob',
      }).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "Kehadiran peserta pelatihan "+this.training.title+".pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.small-gap-right {
  margin-right: 8px;
}

.small-gap-bottom {
  margin-bottom: 8px;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.collapse-default {
  .card {
    // border-bottom: none !important;
  }
  .material-card {
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #efefef !important;
    border-radius: 0.428rem;

    .card-body {
      padding: 1.5rem;
    }
  }
}
</style>

