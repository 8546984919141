<template>
  <!-- Content Add Modal -->
  <b-modal id="certificate-add-modal" centered title="Buat Sertifikat" size="lg" @hidden="resetParticipants()">
    <b-row v-for="(participant, index) in formData" :key="index" v-if="participant.id">
      <b-col md="6" class="mb-1">
        <label class="d-block">Peserta {{ index + 1 }}</label>
        <b-form-input :value="participant.name" readonly />
        <b-form-invalid-feedback v-if="formErrors.jp">
          {{ formErrors.jp[0] }}
        </b-form-invalid-feedback>
      </b-col>
      <b-col lg="6" class="mb-1">
        <b-form-group v-slot="{ ariaDescribedby }" label="Grade" :state="formErrors[`participants.${index}.grade`] ? false : null"
          :invalid-feedback="formErrors[`participants.${index}.grade`] ? formErrors[`participants.${index}.grade`][0] : ''">
          <b-form-radio-group :id="`radio-group-${index}`" v-model="formData[index].grade" :options="[
            { value: 'A', text: 'A' },
            { value: 'B', text: 'B' },
            { value: 'C', text: 'C' },
          ]" :aria-describedby="ariaDescribedby" name="radio-options" />
        </b-form-group>
      </b-col>
    </b-row>
    <template #modal-footer>
      <div class="w-100">
        <b-button variant="outline-danger" class="align-items-center px-1 ml-1 float-right" size="sm" title="Tolak"
          @click="createCertificate()">
          <i class="ti ti-trash" /> Buat Sertifikat
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  BButton,
  BFormInput,
  BFormText,
  BFormGroup,
  BFormRadioGroup,
  BFormTextarea,
  BSpinner,
  BFormFile,
  BRow,
  BCol,
} from 'bootstrap-vue'
import vSelect from "vue-select";
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  reqCreateTrainingCertificate,
} from '@/api/admin/trainingCertificate'

export default {
  components: {
    BModal,
    BButton,
    BFormGroup,
    BFormRadioGroup,
    BFormText,
    BFormInput,
    BFormFile,
    BFormTextarea,
    BSpinner,
    BRow,
    BCol,
    flatPickr,
    vSelect
  },
  directives: {
    Ripple,
  },
  props: {
    participants: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      formData: [],
      formErrors: [],
    }
  },
  watch: {
    participants(values) {
      this.formData = []
      this.formData = values.map(p => {
        return {
          id: p.id,
          name: p.fullName,
          grade: '',
        }
      })
    }
  },
  methods: {
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    resetParticipants() {
      this.$emit('reset')
    },
    createCertificate() {
      reqCreateTrainingCertificate(this.$route.params.trainingId, {
        participants: this.formData.map(p => {
          return {
            id: p.id,
            grade: p.grade,
          }
        }),
      })
        .then(response => {
          this.showToast(
            'success',
            'CheckIcon',
            'Berhasil',
            response.data.message || 'Berhasil membuat sertifikat',
          )
          this.$bvModal.hide('certificate-add-modal')
          this.$emit('reload')
        })
        .catch(error => {
          this.formErrors = error.response.data.errors
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Gagal',
              icon: 'XIcon',
              text: error.response?.data?.message || 'Gagal membuat sertifikat',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
#content-add-modal {
  .modal-body {
    padding: 1.5rem 2rem;
  }

  .modal-footer {
    display: none !important;
  }
}
</style>
