<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <!-- Header -->
          <div class="d-flex align-items-center line-height-1 pb-2">
            <i class="text-primary ti ti-users small-gap-right" />
            <h4 class="text-primary mb-0">
              Daftar Sertifikat
            </h4>
          </div>

          <!-- Table of Student-->
          <div>
            <!-- table -->
            <vue-good-table ref="certificate-table" :line-numbers="true" :columns="columns" :rows="rows"
              :total-rows="rows.length" :is-loading.sync="isLoading" :sort-options="{
                enabled: true,
                initialSortBy: { field: 'fullName', type: 'asc' },
              }" :select-options="{
  enabled: true,
  selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
  selectionInfoClass: 'custom-class',
  selectionText: 'data user terpilih',
  clearSelectionText: 'Reset',
  selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
}" :pagination-options="{
  enabled: true,
  nextLabel: 'selanjutnya',
  prevLabel: 'sebelumnya',
  rowsPerPageLabel: 'Data per halaman',
  ofLabel: 'dari',
  pageLabel: 'halaman', // for 'pages' mode
  allLabel: 'Semua',
}">
              <div slot="table-actions">
                <vue-excel-xlsx :data="dataExcel" :columns="columnsExcel"
                  :file-name="'Daftar sertifikat peserta pelatihan ' + training.title" :file-type="'xlsx'"
                  :sheet-name="'sheetname'" class="mr-1 btn btn-warning btn-sm">
                  <i class="bi bi-download"></i> Download
                </vue-excel-xlsx>
              </div>
              <template slot="selected-row-actions">
                <b-button variant="outline-success" class="align-items-center px-1 ml-1" size="md" title="Buat Sertifikat"
                  @click="handleCreateCertificate()">
                  <i class="ti ti-check" />Buat Sertifikat
                </b-button>
              </template>
              <template slot="table-row" slot-scope="props">
                <!-- Column: Name -->
                <span v-if="props.column.field === 'fullName'" class="text-nowrap">
                  <b-avatar :text="avatarText(props.row.fullName)" :src="props.row.avatar" variant="light-primary"
                    class="mx-1" />
                  <span>{{ props.row.fullName }}</span>
                </span>

                <span v-else-if="props.column.field === 'has_certificate'" class="text-nowrap">
                  <b-button v-if="props.row.has_certificate" variant="primary" size="sm" :href="`${baseUrl}/training-certificates/${props.row.certificate_id}`" target="_blank">Buka</b-button>
                  <span v-else>-</span>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <b-button v-if="props.row.has_certificate" variant="outline-danger" class="align-items-center px-1"
                    size="sm" title="Hapus" @click="handleDelete(props)">
                    <i class="ti ti-trash" />
                  </b-button>
                  <b-button v-if="!props.row.has_certificate" variant="outline-success" class="align-items-center px-1"
                    size="sm" title="Terima" @click="handleCreateCertificate(props.row)">
                    <i class="ti ti-check" />
                  </b-button>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <certificate-add-modal :participants="participants" @reload="reload()" @reset="reset()" />
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { pascalCase, avatarText } from '@core/utils/filter'
import {
  reqGetTrainingCertificates,
  reqDeleteTrainingCertificate,
} from '@/api/admin/trainingCertificate'
import CertificateAddModal from '../component/CertificateAddModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
    CertificateAddModal
  },
  directives: {
    Ripple,
  },
  props: {
    active: {
      required: true,
      type: Boolean,
    },
    training: {
      required: true,
    },
  },
  data() {
    return {
      participants: [],
      isLoading: false,
      currentPage: 1,
      pageLength: 5,
      options: ['5', '10', '30'],
      columns: [
        {
          label: 'Nama User',
          field: 'fullName',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Cari nama user', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: 'keyup', // only trigger on enter not on keyup
          },
        },
        {
          label: 'Email',
          field: 'email',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Cari email user', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, // custom filter function that
            trigger: 'keyup', // only trigger on enter not on keyup
          },
        },
        {
          label: 'Status',
          field: 'has_certificate',
          tdClass: 'text-center',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Semua status', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [
              {
                value: 1,
                text: 'Sudah Dibuat',
              },
              {
                value: 0,
                text: 'Belum Dibuat',
              },
            ],
            filterFn: this.columnFilterFn, // custom filter function that
            trigger: 'enter', // only trigger on enter not on keyup
          },
        },
        {
          label: 'Aksi',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
      rows: [],
      searchTerm: '',
      columnsExcel: [
        {
          label: "Nama",
          field: "fullName",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Sertifikat",
          field: "certificate_file",
        }
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    dataExcel() {
      return this.$refs.certificateTable && this.$refs.certificateTable.filteredRows ? this.$refs.certificateTable.filteredRows[0].children : this.rows
    },
    baseUrl() {
      return process.env.VUE_APP_BASE_API_URL + "/api";
    },
  },
  watch: {
    active(value) {
      if (value) {
        this.getParticipants()
      }
    },
  },
  created() { },
  methods: {
    triggerModal() {
      this.$bvModal.show('certificate-add-modal')
    },
    reload() {
      this.reset()
      this.getParticipants()
    },
    reset() {
      this.participants = []
    },
    pascalCase,
    avatarText,
    columnFilterFn(data, search) {
      console.log(search, data)
      if (typeof data === 'string' && typeof search === 'string') {
        data = data.toLowerCase()
        search = search.toLowerCase()
        if (data.includes(search)) {
          return data
        }
      }
      return data == search
    },
    async getParticipants() {
      this.isLoading = true
      await reqGetTrainingCertificates(this.$route.params.trainingId)
        .then(response => {
          const students = response.data.data

          const listOfStudents = students.map(student => ({
            id: student.id,
            email: student.user.email,
            avatar: student.user.avatar,
            fullName: pascalCase(student.user.name),
            has_certificate: student.training_certificate ? 1 : 0,
            certificate_id: student.training_certificate ? student.training_certificate.id : null,
            certificate_file: student.training_certificate ? student.training_certificate.file : 'Belum ada',
          }))
          this.rows = listOfStudents
          this.isLoading = false
        })
        .catch(error => {
          console.log(error)
          this.isLoading = false
        })
    },
    handleCreateCertificate(row = null) {
      if (row) {
        this.participants.push(row)
      } else {
        this.participants = this.$refs['certificate-table'].selectedRows.map(row => row)
      }
      this.triggerModal()
    },
    handleDelete(props) {
      this.$swal({
        title: 'Apakah Anda Yakin?',
        text: 'Anda tidak akan bisa mengembalikan data ini lagi!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
      }).then(result => {
        if (result.isConfirmed) {
          reqDeleteTrainingCertificate(props.row.certificate_id)
            .then(response => {
              this.showToast(
                'success',
                'CheckIcon',
                'Berhasil',
                response.data.message || 'Berhasil menghapus data',
              )
              this.getParticipants()
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Gagal',
                  icon: 'XIcon',
                  text:
                    error.response.data.message || 'Gagal menghapus data',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.small-gap-right {
  margin-right: 8px;
}

.small-gap-bottom {
  margin-bottom: 8px;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.collapse-default {
  .card {
    // border-bottom: none !important;
  }

  .material-card {
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #efefef !important;
    border-radius: 0.428rem;

    .card-body {
      padding: 1.5rem;
    }
  }
}
</style>
