<template>
  <div>
    <!-- Task Card Header -->
    <b-card
      class="card mb-1"
      title="Pengaturan Pelatihan"
    >
      <!-- Title -->
      <div class="d-flex pb-2 border-bottom">
        <b-avatar
          :src="blobImage"
          :text="avatarText('Kelas')"
          :variant="`light-primary`"
          size="104px"
          rounded
        />
        <div class="ml-1 d-flex flex-column justify-content-center">
          <div class="d-flex mb-1">
            <input
              ref="file"
              accept="image/png, image/jpg, image/jpeg"
              type="file"
              style="display: none"
              @change="onFileChange"
            >
            <b-button
              variant="outline-primary small-gap-right"
              @click="$refs.file.click()"
            >
              Upload Foto Baru
            </b-button>
            <b-button
              variant="secondary"
              @click="blobImage = ''"
            >
              Reset
            </b-button>
          </div>
          <span>Upload foto pelatihan format jpg/png maks. 800kb</span>
        </div>
      </div>

      <!-- Classroom Config -->
      <div class="mt-2">
        <b-row>
          <b-col sm="12">
            <label class="d-block">Banner</label>
            <div class="image-upload-container">
              <b-card
                v-for="(item, index) in formData.banners"
                :key="index"
                no-body
                class="image-upload-card text-center"
                border-variant="secondary"
              >
                <div class="image-upload">
                  <img
                    :src="item.url"
                    alt=""
                  >
                </div>
                <b-button
                  size="sm"
                  variant="danger"
                  class="m-0 btn-sm remove-button"
                  type="button"
                  @click="removeSponsor(index)"
                >
                  <span class="m-auto">x</span>
                </b-button>
              </b-card>
              <b-card
                no-body
                class="image-upload-card text-center"
                border-variant="secondary"
                @click="$refs.fileSponsor.click()"
              >
                <input
                  ref="fileSponsor"
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  class="d-none"
                  @change="addSponsor"
                >
                <i class="ti ti-plus ti" />
              </b-card>
            </div>
            <div
              v-if="formErrors['sponsors']"
              class="d-block invalid-feedback"
            >
              <div
                v-for="(item, index) in formErrors['sponsors']"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
          </b-col>
          <b-col
            lg="12"
            class="mb-1"
          >
            <b-form-group
              v-slot="{ ariaDescribedby }"
              label="Status"
              :state="formErrors.status ? false : null"
              :invalid-feedback="formErrors.status ? formErrors.status[0] : ''"
            >
              <b-form-radio-group
                id="radio-group-status"
                v-model="formData.status"
                :options="[
                  { value: 'Belum Mulai', text: 'Belum Mulai' },
                  { value: 'Pendaftaran', text: 'Masa Pendaftaran' },
                  { value: 'Sedang Berlangsung', text: 'Sedang Berlangsung' },
                  { value: 'Selesai', text: 'Selesai' },
                ]"
                :aria-describedby="ariaDescribedby"
                name="radio-option-status"
              />
            </b-form-group>
          </b-col>
          <b-col
            lg="12"
            class="mb-1"
          >
            <label class="d-block">Nama</label>
            <b-form-input
              id="classroom-name"
              v-model="formData.title"
              placeholder="Masukkan Nama Pelatihan"
              :state="formErrors.title ? false : null"
            />
            <b-form-invalid-feedback v-if="formErrors.title">
              {{ formErrors.title[0] }}
            </b-form-invalid-feedback>
          </b-col>
          <b-col
            lg="12"
            class="mb-1"
          >
            <label class="d-block">Gratis?</label>
            <div class="d-flex align-items-center">
              <b-form-checkbox
                v-model="formData.is_free"
                name="check-button"
                switch
                inline
                class="mr-0"
                :value="1"
              />
              <span class="line-height-small"> Ya </span>
            </div>
            <small
              id="emailHelp"
              class="form-text text-muted"
            >Jika diaktifkan maka user tidak perlu membayar untuk
              bergabung di pelatihan ini</small>
          </b-col>
          <b-col
            v-if="!formData.is_free"
            md="6"
            class="mb-1"
          >
            <label class="d-block">Harga</label>
            <b-form-input
              v-model="formData.price"
              type="number"
              placeholder="Masukkan Harga"
              :state="formErrors.price ? false : null"
            />
            <b-form-invalid-feedback v-if="formErrors.price">
              {{ formErrors.price[0] }}
            </b-form-invalid-feedback>
          </b-col>
          <b-col
            v-if="!formData.is_free"
            md="6"
            class="mb-1"
          >
            <label class="d-block">Diskon</label>
            <b-form-input
              v-model="formData.discount"
              type="number"
              placeholder="Masukkan Diskon"
              :state="formErrors.discount ? false : null"
            />
            <b-form-invalid-feedback v-if="formErrors.discount">
              {{ formErrors.discount[0] }}
            </b-form-invalid-feedback>
          </b-col>
          <b-col
            lg="6"
            class="mb-1"
          >
            <label class="d-block">Penanggung Jawab</label>
            <v-select
              v-model="formData.responsible_id"
              :reduce="(trainer) => trainer.id"
              :options="staffs"
              label="name"
            />
            <span
              v-if="formErrors.responsible_id"
              class="text-danger"
            >{{ formErrors.responsible_id[0] }}</span>
          </b-col>
          <b-col
            lg="6"
            class="mb-1"
          >
            <label class="d-block">Pelatih</label>
            <v-select
              v-model="formData.trainer_id"
              :reduce="(trainer) => trainer.id"
              :options="trainers"
              label="name"
            />
            <span
              v-if="formErrors.trainer_id"
              class="text-danger"
            >{{ formErrors.trainer_id[0] }}</span>
          </b-col>
          <b-col
            lg="12"
            class="mb-1"
          >
            <label class="d-block">Deskripsi</label>
            <b-form-textarea
              v-model="formData.description"
              placeholder="Deskripsi Pelatihan"
              rows="3"
              no-resize
              :state="formErrors.description ? false : null"
            />
            <b-form-invalid-feedback v-if="formErrors.description">
              {{ formErrors.description[0] }}
            </b-form-invalid-feedback>
          </b-col>
          <b-col
            lg="12"
            class="mb-1"
          >
            <label class="d-block">Pengumuman</label>
            <b-form-textarea
              v-model="formData.announcement"
              placeholder="Pengumuman Pelatihan"
              rows="3"
              no-resize
              :state="formErrors.announcement ? false : null"
            />
            <b-form-invalid-feedback v-if="formErrors.description">
              {{ formErrors.description[0] }}
            </b-form-invalid-feedback>
          </b-col>
          <b-col
            lg="6"
            class="mb-1"
          >
            <b-form-group
              v-slot="{ ariaDescribedby }"
              label="Level"
            >
              <b-form-radio-group
                id="radio-group-1"
                v-model="formData.level"
                :options="[
                  { value: 1, text: 'Basic' },
                  { value: 2, text: 'Intermediate' },
                  { value: 3, text: 'Advance' },
                ]"
                :aria-describedby="ariaDescribedby"
                name="radio-options"
              />
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            class="mb-1"
          >
            <label class="d-block">Total JP</label>
            <b-form-input
              v-model="formData.jp"
              type="number"
              placeholder="Masukkan total JP"
              :state="formErrors.jp ? false : null"
            />
            <b-form-invalid-feedback v-if="formErrors.jp">
              {{ formErrors.jp[0] }}
            </b-form-invalid-feedback>
          </b-col>

          <!-- Start Date -->
          <b-col md="6">
            <label class="d-block">Tanggal Mulai </label>
            <b-form-group :state="formErrors.start_at ? false : null">
              <flat-pickr
                v-model="formData.start_at"
                class="form-control"
                :class="{
                  'is-invalid': formErrors.start_at,
                }"
                :config="{
                  altInput: true,
                  altFormat: 'j F Y (H:i)',
                  dateFormat: 'Y-m-d H:i',
                  enableTime: true,
                  time_24hr: true,
                }"
              />
            </b-form-group>
            <div
              v-if="formErrors.start_at"
              class="invalid-feedback"
            >
              {{ formErrors.start_at[0] }}
            </div>
          </b-col>

          <!-- Start Date -->
          <b-col md="6">
            <label class="d-block">Tanggal Berakhir </label>
            <b-form-group :state="formErrors.end_at ? false : null">
              <flat-pickr
                v-model="formData.end_at"
                class="form-control"
                :class="{
                  'is-invalid': formErrors.end_at ? false : false,
                }"
                :config="{
                  altInput: true,
                  altFormat: 'j F Y (H:i)',
                  dateFormat: 'Y-m-d H:i',
                  enableTime: true,
                  time_24hr: true,
                }"
              />
            </b-form-group>
            <div
              v-if="formErrors.end_at"
              class="invalid-feedback"
            >
              {{ formErrors.end_at[0] }}
            </div>
          </b-col>

          <!-- technical skills -->
          <b-col md="12">
            <label class="d-block">Technical Skills </label>
            <!-- <b-form-input v-model="formData.technical_skills[index]" type="text" placeholder="Masukkan skill teknis"
              :state="formErrors.technical_skills ? false : null" v-for="(skill, index) in formData.technical_skills"
              :key="index" /> -->
            <b-input-group
              v-for="(skill, index) in formData.technical_skills"
              :key="index"
              :state="formErrors.technical_skills ? false : null"
              class="mb-1 is-invalid"
            >
              <b-form-input
                v-model="formData.technical_skills[index]"
                type="text"
                :placeholder="`Masukkan skill teknis ${index + 1}`"
                :state="formErrors.technical_skills ? false : null"
              />
              <b-input-group-append>
                <b-button
                  v-if="index >= 1"
                  variant="danger"
                  @click="formData.technical_skills.splice(index, 1)"
                >
                  Hapus
                </b-button>
                <b-button
                  v-if="index === formData.technical_skills.length - 1"
                  variant="success"
                  @click="formData.technical_skills.push('')"
                >
                  Tambah
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-form-invalid-feedback v-if="formErrors.technical_skills">
              {{ formErrors.technical_skills[0] }}
            </b-form-invalid-feedback>
          </b-col>

          <b-col
            lg="12"
            class="mb-1"
          >
            <label class="d-block">Dapat Dilihat User?</label>
            <div class="d-flex align-items-center">
              <b-form-checkbox
                v-model="formData.is_visible"
                name="check-button"
                switch
                inline
                class="mr-0"
                :value="1"
              />
              <span class="line-height-small"> Ya </span>
            </div>
            <small
              id="emailHelp"
              class="form-text text-muted"
            >Jika diaktifkan maka semua User dapat melihat pelatihan
              ini</small>
          </b-col>

          <b-col
            lg="12"
            class="mb-1"
          >
            <label class="d-block">Gunakan kode?</label>
            <div class="d-flex align-items-center">
              <b-form-checkbox
                v-model="useCode"
                name="check-button"
                switch
                inline
                class="mr-0"
                :value="1"
              />
              <span class="line-height-small"> Ya </span>
            </div>
            <small
              id="emailHelp"
              class="form-text text-muted"
            >Jika kode ditambahkan maka hanya User yang mengetahui kode
              tersebut yang dapat bergabung</small>
          </b-col>
          <b-col
            v-if="useCode"
            lg="12"
            class="mb-1"
          >
            <b-form-group
              v-slot="{ ariaDescribedby }"
              label="Kode"
              :state="formErrors.code ? false : null"
              :invalid-feedback="formErrors.code ? formErrors.code[0] : ''"
            >
              <b-form-input
                v-model="formData.code"
                :aria-describedby="ariaDescribedby"
                placeholder="Masukkan Kode"
                :state="formErrors.code ? false : null"
              />
            </b-form-group>
          </b-col>

          <b-col
            lg="12"
            class="mb-1"
          >
            <label class="d-block">Perlu Persetujuan?</label>
            <div class="d-flex align-items-center">
              <b-form-checkbox
                v-model="formData.need_approval"
                name="check-button"
                switch
                inline
                class="mr-0"
                :value="1"
              />
              <span class="line-height-small"> Ya </span>
            </div>
            <small
              id="emailHelp"
              class="form-text text-muted"
            >Jika diaktifkan maka Admin harus melakukan persetujuan
              pendaftaran</small>
          </b-col>

          <b-col
            lg="12"
            class="mb-1"
          >
            <label class="d-block">Ada Persyaratan?</label>
            <div class="d-flex align-items-center">
              <b-form-checkbox
                v-model="formData.need_requirement"
                name="check-button"
                switch
                inline
                class="mr-0"
              />
            </div>
            <small
              id="emailHelp"
              class="form-text text-muted"
            >Persyaratan dokumen atau persyartan telah mengikuti
              pelatihan tertentu</small>
          </b-col>

          <b-col
            v-if="formData.need_requirement"
            lg="12"
            class="mb-1"
          >
            <label class="d-block">Deskripsi Persyaratan Unggah Dokumen</label>
            <b-form-textarea
              v-model="formData.requirement_description"
              placeholder="Deskripsi Persyaratan Pelatihan"
              rows="3"
              no-resize
              :state="formErrors.requirement_description ? false : null"
            />
            <b-form-text id="desc-help-block">
              Jika diisi maka peserta pelatihan harus mengunggah file yang tertera dideskripsi ini
            </b-form-text>
            <b-form-invalid-feedback v-if="formErrors.requirement_description">
              {{ formErrors.requirement_description[0] }}
            </b-form-invalid-feedback>
          </b-col>

          <b-col
            v-if="formData.need_requirement"
            lg="12"
            class="mb-1"
          >
            <label class="d-block">Persyaratan Pelatihan</label>
            <v-select
              v-model="formData.requirement_trainings"
              multiple
              :reduce="(training) => training.id"
              :options="trainings"
              label="title"
              :class="{
                'is-invalid': formErrors.end_at,
              }"
            />
            <b-form-text id="req-help-block">
              Jika diisi maka peserta pelatihan harus memiliki sertifikat pelatihan yang disebutkan
            </b-form-text>
            <div
              v-if="formErrors.requirement_trainings"
              class="invalid-feedback d-block"
            >
              {{ formErrors.requirement_trainings[0] }}
            </div>
          </b-col>
        </b-row>
        <div>
          <b-button
            variant="primary"
            class="float-right"
            @click="submitForm"
          >
            <div class="d-flex align-items-center">
              <b-spinner
                v-if="isLoading"
                class="mr-1"
                variant="light"
                small
              />
              Simpan
            </div>
          </b-button>
          <b-button
            v-if="isAdmin"
            variant="warning"
            class="float-right mr-1"
            type="button"
            @click="deleteTraining()"
          >
            <div class="d-flex align-items-center">
              Hapus Pelatihan
            </div>
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCardText,
  BAlert,
  BAvatar,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormTextarea,
  BFormInvalidFeedback,
  BFormRadioGroup,
  BFormText,
  BSpinner,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'

import { reqGetTrainings, reqUpdateTraining, reqDeleteTraining } from '@/api/admin/training'
import { reqGetTrainers } from '@/api/admin/trainer'
import { reqGetStaffs } from '@/api/admin/staff'
import { checkAuthorizeRole, isAdmin } from '@/auth/utils'

export default {
  components: {
    BFormInvalidFeedback,
    BRow,
    BCol,
    BCard,
    BButton,
    BCardText,
    BAlert,
    BAvatar,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormTextarea,
    BFormRadioGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormText,
    BSpinner,
    vSelect,
    flatPickr,
  },
  props: {
    training: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      isOpenClass: false,
      blobImage: '',
      formErrors: {},
      formData: {
        is_free: 1,
        price: 0,
        discount: 0,
        title: '',
        image: null,
        description: '',
        announcement: '',
        level: '',
        start_at: '',
        end_at: '',
        is_visible: 0,
        status: '',
        code: '',
        jp: '',
        need_approval: 1,
        banners: [],
        delete_banners: [],
        need_requirement: false,
        requirement_description: undefined,
        requirement_trainings: [],
        technical_skills: [''],
        trainer_id: '',
        responsible_id: '',
      },
      useCode: false,
      trainings: [],
      trainers: [],
      staffs: [],
    }
  },
  mounted() {
    this.getTrainings()
    this.getTrainers()
    this.getStaffs()
    this.blobImage = this.training.image
    this.formData.is_free = this.training.price ? 0 : 1
    this.formData.price = this.training.price
    this.formData.discount = this.training.discount
    this.formData.title = this.training.title
    this.formData.trainer_id = this.training.trainer_id
    this.formData.responsible_id = this.training.responsible_id
    this.formData.description = this.training.description
    this.formData.announcement = this.training.announcement
    this.formData.level = this.training.level
    this.formData.start_at = this.training.start_at
    this.formData.end_at = this.training.end_at
    this.formData.is_visible = this.training.is_visible
    this.formData.status = this.training.status
    this.formData.code = this.training.code
    this.formData.need_approval = this.training.need_approval
    this.formData.jp = this.training.jp
    this.formData.banners = this.training.training_banners.map(banner => ({
      id: banner.id,
      url: banner.image,
    }))
    this.formData.need_requirement = !!this.training.need_requirement
    this.formData.requirement_description = this.training.requirement_description
    this.formData.requirement_trainings = this.training.requirements.map(training => training.pivot.requirement_training_id)
    this.formData.technical_skills = this.training.training_technical_skills.length ? this.training.training_technical_skills.map(skill => skill.name) : ['']
    this.useCode = this.training.code ? 1 : 0
  },
  methods: {
    avatarText,
    isAdmin,
    checkAuthorizeRole,
    getTrainers() {
      reqGetTrainers({ per_page: 999 }).then(response => {
        this.trainers = response.data.data.data
      })
    },
    getStaffs() {
      reqGetStaffs({ per_page: 999 }).then(response => {
        this.staffs = response.data.data.data
      })
    },
    onFileChange(e) {
      const file = e.target.files[0]
      this.formData.image = file
      this.blobImage = URL.createObjectURL(file)
    },
    getTrainings() {
      reqGetTrainings({ per_page: 999 }).then(response => {
        this.trainings = response.data.data.data
      })
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    validateForm() {
      const isValid = true
      return isValid
    },
    async submitForm() {
      this.isLoading = true
      if (this.validateForm()) {
        const data = new FormData()
        for (const i in this.formData) {
          if (i == 'banners' && this.formData.banners.length) {
            this.formData.banners.forEach((banner, index) => {
              if (banner.file) {
                data.append(`new_banners[${index}]`, banner.file)
              }
            })
          } else if (i == 'need_requirement' || i == 'need_approval' || i == 'is_visible') {
            data.append(i, this.formData[i] == 1 || this.formData[i] == true ? 1 : 0)
          } else if (i == 'requirement_trainings') {
            if (this.formData.requirement_trainings.length) {
              this.formData.requirement_trainings.forEach((element, index) => {
                data.append(`requirement_trainings[${index}]`, element)
              })
            }
          } else if (i == 'delete_banners') {
            if (this.formData.delete_banners.length) {
              this.formData.delete_banners.forEach((element, index) => {
                data.append(`delete_banners[${index}]`, element)
              })
            }
          } else if (i == 'code') {
            if (this.useCode && this.formData[i]) {
              data.append(i, this.formData[i])
            }
          } else if (i == 'is_free' && this.formData.is_free) {
            this.formData.price = 0
            this.formData.discount = 0
            data.append('price', 0)
            data.append('discount', 0)
          } else if (i == 'technical_skills') {
            this.formData.technical_skills.forEach((skill, index) => {
              if (skill) { data.append(`technical_skills[${index}]`, skill) }
            })
          } else if (this.formData[i]) {
            data.append(i, this.formData[i])
          }
        }
        // create classroom
        await reqUpdateTraining(this.$route.params.trainingId, data)
          .then(response => {
            // stop loading
            this.isLoading = false

            // show success toast
            this.showToast(
              'success',
              'CheckIcon',
              'Berhasil',
              'Pelatihan berhasil disimpan',
            )
            this.formData.delete_banners = []
            this.formData.banners = this.formData.banners.map(banner => ({
              url: banner.url,
            }))
            this.$emit('reload')
          })
          .catch(error => {
            this.isLoading = false
            this.formErrors = error.response.data.errors
            this.showToast(
              'danger',
              'XIcon',
              'Gagal',
              error.response.data.message || 'Pelatihan gagal dibuat',
            )
          })
      }
    },
    deleteTraining() {
      this.$swal({
        title: 'Apakah Anda Yakin?',
        text: `Yakin ingkin menghapus pelatihan ${this.formData.title}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
      }).then(result => {
        if (result.isConfirmed) {
          reqDeleteTraining(this.$route.params.trainingId)
            .then(response => {
              this.$toast(
                'success',
                'CheckIcon',
                'Berhasil',
                response.data.message || 'Berhasil menghapus data',
              )
              this.$router.push({
                name: checkAuthorizeRole('trainings.index'),
              })
            })
            .catch(error => {
              this.showToast(
                'danger',
                'XIcon',
                'Gagal',
                error.response.data.message || 'Gagal menghapus data',
              )
            })
        }
      })
    },
    addSponsor(e) {
      const file = e.target.files[0]
      const url = URL.createObjectURL(file)
      this.formData.banners.push({
        file,
        url,
      })
    },
    removeSponsor(index) {
      if (this.formData.banners[index].id) {
        this.formData.delete_banners.push(this.formData.banners[index].id)
      }
      this.formData.banners.splice(index, 1)
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.line-height-small {
  line-height: 1.5rem;
}

.small-gap-right {
  margin-right: 8px;
}

.small-gap-top {
  margin-top: 8px;
}

.small-gap-bottom {
  margin-bottom: 8px;
}

.regular-icon {
  top: 0 !important;
  font-size: 1.2rem;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.card-icon-lg {
  top: 0 !important;
  font-size: 2.5rem;
}

.pointer {
  cursor: pointer;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.image-upload-container {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  overflow-x: auto;
  position: relative;
}

.image-upload-container .image-upload-card {
  margin: 15px;
  margin-left: 0px;
  min-width: 200px;
  min-height: 100px;
  width: 200px;
  height: 100px;
  cursor: pointer;
  object-fit: fill;
  padding: 1px;
  background: white;
}

.image-upload-container .image-upload-card i {
  margin: auto;
  font-size: 40px;
}

.image-upload-container .image-upload-card .image-upload {
  width: 100%;
  height: 100%;
  object-fit: fill;
  overflow: hidden;
}

.image-upload-container .image-upload-card .image-upload img {
  width: 100%;
  height: 100%;
}

.remove-button {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  padding: 0px;
}
</style>
