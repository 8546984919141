var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"classroom-header mb-1"},[_c('b-carousel',{staticClass:"training-banner",staticStyle:{"text-shadow":"1px 1px 2px #333"},attrs:{"id":"carousel-1","interval":4000,"controls":"","indicators":"","background":"#ababab","img-height":"200px"}},_vm._l((_vm.headerData.classroomCovers),function(item,index){return _c('b-carousel-slide',{key:index,scopedSlots:_vm._u([{key:"img",fn:function(){return [_c('img',{staticClass:"d-block w-100 training-banner",attrs:{"src":item.image,"alt":"image slot"}})]},proxy:true}],null,true)})}),1),_c('div',{staticClass:"classroom-header-nav"},[_c('div',{staticClass:"p-2 d-md-flex align-items-center"},[_c('b-img',{staticClass:"classroom-img",attrs:{"text":_vm.avatarText(_vm.headerData.classroomTitle),"src":_vm.headerData.classroomLogo,"rounded":"","fluid":"","alt":"classroom photo"}}),_c('div',{staticClass:"w-100 ml-md-2 mt-1 mt-md-0 d-flex justify-content-between"},[_c('div',[_c('h2',[_vm._v(" "+_vm._s(_vm.headerData.classroomTitle)+" ")]),_c('div',[_c('span',{staticClass:"badge d-block d-md-inline",class:[
                _vm.headerData.status == 'Belum Mulai'
                  ? 'badge-secondary'
                  : _vm.headerData.status == 'Pendaftaran'
                  ? 'badge-warning'
                  : _vm.headerData.status == 'Sedang Berlangsung'
                  ? 'badge-danger'
                  : 'badge-success' ]},[_vm._v(_vm._s(_vm.headerData.status))]),_c('span',{staticClass:"text-danger ml-md-1"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.headerData.start_at,"Do MMM YYYY"))+" - "+_vm._s(_vm._f("moment")(_vm.headerData.end_at,"Do MMM YYYY"))+" ")])]),_c('div',{staticClass:"d-flex mt-1"},[_c('b-badge',{staticClass:"mr-1",attrs:{"variant":"light-info"}},[_c('i',{staticClass:"ti ti-user"}),_vm._v(" "+_vm._s(_vm.headerData.teacherName)+" ")])],1)])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }