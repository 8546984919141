<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <!-- Header -->
          <div class="d-flex align-items-center line-height-1 pb-2">
            <i class="text-primary ti ti-users small-gap-right" />
            <h4 class="text-primary mb-0">
              Daftar Peserta
            </h4>
          </div>
          <!-- Table of Student-->
          <div>
            <!-- table -->
            <vue-good-table
              ref="table"
              :line-numbers="true"
              :columns="columns"
              :rows="rows"
              :total-rows="rows.length"
              :is-loading.sync="isLoading"
              :sort-options="{
                enabled: true,
                initialSortBy: { field: 'fullName', type: 'asc' },
              }"
              :select-options="{
                // enabled: true,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                nextLabel: 'selanjutnya',
                prevLabel: 'sebelumnya',
                rowsPerPageLabel: 'Data per halaman',
                ofLabel: 'dari',
                pageLabel: 'halaman', // for 'pages' mode
                allLabel: 'Semua',
              }"
            >
              <div slot="table-actions">
                <vue-excel-xlsx
                  :data="dataExcel"
                  :columns="columnsExcel"
                  :file-name="'Daftar peserta pelatihan ' + training.title"
                  :file-type="'xlsx'"
                  :sheet-name="'sheetname'"
                  class="mr-1 btn btn-warning btn-sm"
                >
                  <i class="bi bi-download" /> Download
                </vue-excel-xlsx>
                <b-button
                  variant="primary"
                  size="sm"
                  @click="$bvModal.show('participant-add-modal')"
                >
                  <i class="bi bi-plus" />
                  Tambah
                </b-button>
              </div>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Name -->
                <span
                  v-if="props.column.field === 'fullName'"
                  class="text-nowrap"
                >
                  <b-avatar
                    :text="avatarText(props.row.fullName)"
                    :src="props.row.avatar"
                    variant="light-primary"
                    class="mx-1"
                  />
                  {{ props.row.fullName }}
                </span>

                <span
                  v-else-if="props.column.field === 'status'"
                  class="text-nowrap"
                >
                  <b-badge
                    :variant="
                      props.row.status == 'Diterima'
                        ? 'success'
                        : props.row.status == 'Ditolak'
                          ? 'danger'
                          : 'secondary'
                    "
                  >{{ props.row.status }}</b-badge>
                </span>

                <span
                  v-else-if="props.column.field === 'is_done'"
                  class="text-nowrap"
                >
                  <b-badge
                    :variant="props.row.is_done ? 'success' : 'secondary'"
                  >{{ props.row.statusTraining }}</b-badge>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <b-button
                    variant="outline-danger"
                    class="align-items-center px-1 ml-1"
                    size="sm"
                    title="Tolak"
                    @click="showParticipantInfoModal(props)"
                  >
                    <feather-icon icon="SettingsIcon" />
                  </b-button>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </b-card>
      </b-col>
      <participant-info-modal
        :props-participant="selectedParticipant"
        @delete="getParticipants()"
        @accept="handleAccept"
        @reject="handleReject"
      />
      <participant-add-modal @reload="getParticipants()" />
    </b-row>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { pascalCase, avatarText } from '@core/utils/filter'
import { reqGetTrainingParticipants } from '@/api/admin/trainingParticipant'
import ParticipantInfoModal from '../component/ParticipantInfoModal'
import ParticipantAddModal from '../component/ParticipantAddModal'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
    ParticipantInfoModal,
    ParticipantAddModal,
  },
  directives: {
    Ripple,
  },
  props: {
    active: {
      required: true,
    },
    training: {
      required: true,
    },
  },
  data() {
    return {
      selectedParticipant: {},
      isLoading: false,
      currentPage: 1,
      pageLength: 5,
      options: ['5', '10', '30'],
      columns: [
        {
          label: 'Nama User',
          field: 'fullName',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Cari nama user', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn,
            trigger: 'keyup', // only trigger on enter not on keyup
          },
        },
        {
          label: 'Email',
          field: 'email',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Cari email user', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, // custom filter function that
            trigger: 'keyup', // only trigger on enter not on keyup
          },
        },
        {
          label: 'Status Pendaftaran',
          field: 'status',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'semua status', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [
              'Menunggu Persetujuan',
              'Diterima',
              'Ditolak',
            ],
            filterFn: this.columnFilterFn, // custom filter function that
            trigger: 'enter', // only trigger on enter not on keyup
          },
        },
        {
          label: 'Status Pelatihan',
          field: 'is_done',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'semua status', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [
              { value: true, text: 'Lulus' },
              { value: false, text: 'Belum Selesai' },
            ],
            filterFn: this.columnFilterFn, // custom filter function that
            trigger: 'enter', // only trigger on enter not on keyup
          },
        },
        {
          label: 'Aksi',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      columnsExcel: [
        {
          label: 'Nama',
          field: 'fullName',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'No HP',
          field: 'profile.phone',
        },
        {
          label: 'NIK',
          field: 'profile.nik',
        },
        {
          label: 'NIP',
          field: 'profile.nip',
        },
        {
          label: 'Alamat',
          field: 'profile.address',
        },
        {
          label: 'Gelar Depan',
          field: 'profile.gelar_depan',
        },
        {
          label: 'Gelar Belakang',
          field: 'profile.gelar_belakang',
        },
        {
          label: 'Tempat Lahir',
          field: 'profile.tempat_lahir',
        },
        {
          label: 'Tanggal Lahir',
          field: 'profile.tanggal_lahir',
        },
        {
          label: 'Golongan',
          field: 'profile.golongan',
        },
        {
          label: 'Jabatan',
          field: 'profile.jabatan',
        },
        {
          label: 'Status Pendaftaran',
          field: 'status',
        },
        {
          label: 'Status Pelatihan',
          field: 'statusTraining',
        },
        {
          label: 'Sertifikat',
          field: 'certificate',
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    dataExcel() {
      return this.$refs.table && this.$refs.table.filteredRows ? this.$refs.table.filteredRows[0].children : this.rows
    },
  },
  watch: {
    active(value) {
      if (value) {
        this.getParticipants()
      }
    },
  },
  methods: {
    triggerModal() {
      this.$bvModal.show('content-add-modal')
    },
    pascalCase,
    avatarText,
    columnFilterFn(data, search) {
      if (typeof data === 'string' || data instanceof String) {
        if (data.toLowerCase().includes(search.toLowerCase())) {
          return true
        }
      } else {
        const q = !!(search === 'true' || search === true)
        if (q == data) return true
      }
    },
    async getParticipants() {
      this.isLoading = true
      await reqGetTrainingParticipants(this.$route.params.trainingId)
        .then(response => {
          const students = response.data.data

          const listOfStudents = students.map(student => ({
            id: student.id,
            email: student.user.email,
            avatar: student.user.avatar,
            fullName: pascalCase(student.user.name),
            status: student.status,
            is_done: !!student.is_done,
            statusTraining: student.is_done ? 'Lulus' : 'Belum Lulus',
            certificate: student.training_certificate?.file,
            profile: student.user.profile,
            // fullName: student.student_id,
          }))
          this.rows = listOfStudents
          this.isLoading = false
        })
        .catch(error => {
          console.log(error)
          this.isLoading = false
        })
    },
    showParticipantInfoModal(participant) {
      this.selectedParticipant = participant
      this.$bvModal.show('participant-info-modal')
    },
    handleAccept(props) {
      const foundIndex = this.rows.findIndex(x => x.id == props.row.id)
      this.rows[foundIndex].status = 'Diterima'
    },
    handleReject(props) {
      const foundIndex = this.rows.findIndex(x => x.id == props.row.id)
      this.rows[foundIndex].status = 'Ditolak'
    },
    handleDelete(props) {
      this.rows.splice(props.index, 1)
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.small-gap-right {
  margin-right: 8px;
}

.small-gap-bottom {
  margin-bottom: 8px;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.collapse-default {
  .card {
    // border-bottom: none !important;
  }
  .material-card {
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #efefef !important;
    border-radius: 0.428rem;

    .card-body {
      padding: 1.5rem;
    }
  }
}
</style>
